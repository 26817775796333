import {
  SET_SH_FETCHING_FLAG,
  GET_SH_OPERATION_LEADS,
  GET_SH_INCIDENT_DETAILS,
  SET_SH_SELECTED_SUPPORT_AREA,
  GET_SH_ONGOING_PM,
  GET_SH_INCIDENT_COMMENTS_HISTORY,
  GET_SH_INCIDENT_WORKLOG_HISTORY,
  GET_SH_ADMIN_USERS_LIST,
  SET_SH_TOAST_VISIBLE,
  GET_SH_AGENT_ROLES_LIST,
  GET_SH_CDS_USER_DATA,
  GET_SH_BANNER_MESSSAGE,
  GET_SH_SERVICES,
  GET_SH_GLOBAL_CHAT_ACCEPTED_COUNT,
  GET_SH_GLOBAL_AVERAGE_HANDLING_TIME,
  GET_SH_GLOBAL_AVERAGE_WAITING_TIME,
  GET_SH_GLOBAL_CANCELLED_CHATS,
  GET_SH_GLOBAL_ACCEPTED_PENDING_CHATS,
  GET_SH_GLOBAL_AGENT_STATUS,
  GET_SH_GLOBAL_QUEUED_CHATS,
  GET_SH_GLOBAL_REJECTED_BUSY_CHATS,
  GET_SH_GLOBAL_REJECTED_TIMEOUT_CHATS,
  GET_SH_GLOBAL_TOTAL_COUNT,
  GET_SH_VOLUME_INCIDENT_COUNT,
  GET_SH_VOLUME_BAR_GRAPH_COUNT,
  GET_SH_INDIVIDUAL_COUNT,
  GET_SH_GLOBAL_AVAILABLE_ONGOING_CHATS,
  GET_SH_TEAM_TIMELINE,
} from "./types";

const initialState = {
  selectedSupportArea: "",
  operationLeads: [],
  isFetchingOperationLeads: false,
  incidentDetailsData: {},
  isFetchingIncidentDetails: false,
  isFetchingOngoingPM: false,
  ongoingPM: [],
  commentHistory: [],
  worklogHistory: [],
  adminUsersList: [],
  toastVisible: false,
  toastText: "",
  agentRolesList: [],
  cdsUserData: {},
  isFetchingBanner: false,
  isFetchingServices: false,
  serversServices: [],
  chatAcceptedCount: 0,
  individualChatCount: [],
  isFetchingIndividualCount: false,
  isFetchingGlobalAcceptedCount: false,
  averageHandlingTime: 0,
  isFetchingAverageHandlingTime: false,
  averageWaitingTime: 0,
  isFetchingAverageWaitingTime: false,
  cancelledChats: 0,
  isFetchingCancelledChats: false,
  globalPendingAcceptedChat: 0,
  globalAgentStatus: {
    availableagents: 0,
    not_ready: 0,
  },
  globalRejectedBusyChats: 0,
  globalRejectedTimeOutChats: 0,
  globalCount: 0,
  volumeCount: {
    volumeIncidentCount: 0,
    majorIncidentCandidate: 0,
    criticalIncident: 0,
    urgentIncident: 0,
  },
  isFetchingVolumeLabel: false,
  volumeBarCount: {
    assignedIncident: 0,
    assignedRITMS: 0,
  },
  isFetchingVolumeBarGraph: false,
  isFetchingGlobalTotalCount: false,
  isFetchingGlobalPendingChat: false,
  isFetchingGlobalQueuedChat: false,
  isFetchingGlobalRejectedBusyChat: false,
  isFetchingGlobalRejectedTimeOutChat: false,
  isFetchingGlobalAgentStatus: false,
  isFetchingGlobalAvailableOngoingChats: false,
  globalOngoingAvailableChats: 0,
  teamTimeline: [],
  isFetchingAgentTimeline: false,
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case GET_SH_OPERATION_LEADS: {
      return {
        ...state,
        operationLeads: action.payload,
      };
    }
    case GET_SH_INCIDENT_DETAILS: {
      return {
        ...state,
        incidentDetailsData: action.payload,
      };
    }
    case SET_SH_SELECTED_SUPPORT_AREA: {
      return {
        ...state,
        selectedSupportArea: action.payload,
      };
    }
    case GET_SH_ONGOING_PM: {
      return {
        ...state,
        ongoingPM: action.payload,
      };
    }
    case GET_SH_INCIDENT_COMMENTS_HISTORY: {
      return {
        ...state,
        commentHistory: action.payload,
      };
    }
    case GET_SH_INCIDENT_WORKLOG_HISTORY: {
      return {
        ...state,
        worklogHistory: action.payload,
      };
    }
    case GET_SH_ADMIN_USERS_LIST: {
      return {
        ...state,
        adminUsersList: action.payload,
      };
    }
    case SET_SH_TOAST_VISIBLE: {
      return {
        ...state,
        toastVisible: action.payload?.visible,
        toastText: action.payload?.message,
      };
    }
    case GET_SH_AGENT_ROLES_LIST: {
      return {
        ...state,
        agentRolesList: action.payload,
      };
    }
    case GET_SH_CDS_USER_DATA: {
      return {
        ...state,
        cdsUserData: action.payload,
      };
    }
    case GET_SH_BANNER_MESSSAGE: {
      return {
        ...state,
        bannerMessage: action.payload,
      }
    }
    case GET_SH_SERVICES: {
      return {
        ...state,
        serversServices: action.payload,
      };
    }
    case GET_SH_GLOBAL_CHAT_ACCEPTED_COUNT: {
      return {
        ...state,
        chatAcceptedCount: action.payload,
      };
    }
    case GET_SH_INDIVIDUAL_COUNT: {
      return {
        ...state,
        individualChatCount: action.payload,
      };
    }
    case GET_SH_GLOBAL_AVERAGE_HANDLING_TIME: {
      return {
        ...state,
        averageHandlingTime: action.payload,
      };
    }
    case GET_SH_GLOBAL_AVERAGE_WAITING_TIME: {
      return {
        ...state,
        averageWaitingTime: action.payload,
      };
    }
    case GET_SH_GLOBAL_CANCELLED_CHATS: {
      return {
        ...state,
        cancelledChats: action.payload,
      };
    }
    case GET_SH_GLOBAL_ACCEPTED_PENDING_CHATS: {
      return {
        ...state,
        globalPendingAcceptedChat: action.payload,
      };
    }
    case GET_SH_GLOBAL_AGENT_STATUS: {
      return {
        ...state,
        globalAgentStatus: action.payload,
      };
    }
    case GET_SH_GLOBAL_QUEUED_CHATS: {
      return {
        ...state,
        globalQueuedChats: action.payload,
      };
    }
    case GET_SH_GLOBAL_REJECTED_BUSY_CHATS: {
      return {
        ...state,
        globalRejectedBusyChats: action.payload,
      };
    }
    case GET_SH_GLOBAL_REJECTED_TIMEOUT_CHATS: {
      return {
        ...state,
        globalRejectedTimeOutChats: action.payload,
      };
    }
    case GET_SH_GLOBAL_TOTAL_COUNT: {
      return {
        ...state,
        globalCount: action.payload,
      };
    }
    case GET_SH_VOLUME_BAR_GRAPH_COUNT: {
      return {
        ...state,
        volumeBarCount: action.payload,
      };
    }
    case GET_SH_VOLUME_INCIDENT_COUNT: {
      return {
        ...state,
        volumeCount: action.payload,
      };
    }
    case GET_SH_GLOBAL_AVAILABLE_ONGOING_CHATS: {
      return {
        ...state,
        globalOngoingAvailableChats: action.payload,
      };
    }
    case GET_SH_TEAM_TIMELINE: {
      return {
        ...state,
        teamTimeline: action.payload,
      }
    }
    case SET_SH_FETCHING_FLAG: {
      const { apiType, value } = action.payload;
      switch (apiType) {
        case "getOperationLeadersDetails": {
          return {
            ...state,
            isFetchingOperationLeads: value,
          };
        }
        case "getIncidentDetails": {
          return {
            ...state,
            isFetchingIncidentDetails: value,
          };
        }
        case "opeMajorIncident": {
          return {
            ...state,
            isFetchingOpeMajorIncident: value,
          };
        }
        case "majorIncidentCandidate": {
          return {
            ...state,
            isFetchingMajorIncidentCandidate: value,
          };
        }
        case "openCriticalIncident": {
          return {
            ...state,
            isFetchingOpenCriticalIncident: value,
          };
        }
        case "openUrgentIncidents": {
          return {
            ...state,
            isFetchingOpenUrgentIncidents: value,
          };
        }
        case "getOngoingPowerMaintenance": {
          return {
            ...state,
            isFetchingOngoingPM: value,
          };
        }
        case "addIncidentToCSH": {
          return {
            ...state,
            isFetchingAddToCSH: value,
          };
        }
        case "getCommentHistory": {
          return {
            ...state,
            isFetchingCommentHistory: value,
          };
        }
        case "getWorklogHistory": {
          return {
            ...state,
            isFetchingWeblogHistory: value,
          };
        }
        case "getShiftHandoverAdminList": {
          return {
            ...state,
            isFetchingAdminList: value,
          };
        }
        case "getShiftHandoverAgentRolesList": {
          return {
            ...state,
            isFetchingAgentRoleList: value,
          };
        }
        case "getCDSUserData": {
          return {
            ...state,
            isFetchingCDSData: value,
          };
        }
        case "getBanner": {
          return {
            ...state,
            isFetchingBanner: value
          }
        }
        case "getServersServices": {
          return {
            ...state,
            isFetchingServices: value
          }
        }
        case "getGlobalAcceptedCount": {
          return {
            ...state,
            isFetchingGlobalAcceptedCount: value
          }
        }
        case "getGlobalAverageWaitingTime": {
          return {
            ...state,
            isFetchingAverageWaitingTime: value
          }
        }
        case "getGlobalAveragehandlingTime": {
          return {
            ...state,
            isFetchingAverageHandlingTime: value
          }
        }
        case "getGlobalCancelledChats": {
          return {
            ...state,
            isFetchingCancelledChats: value
          }
        }
        case "getVolumeLabelData": {
          return {
            ...state,
            isFetchingVolumeLabel: value
          }
        }
        case "getVolumeLabelBarGraphData": {
          return {
            ...state,
            isFetchingVolumeBarGraph: value
          }
        }
        case "getGlobalTotalCount": {
          return {
            ...state,
            isFetchingGlobalTotalCount: value
          }
        }
        case "getGlobalPendingAcceptedChats": {
          return {
            ...state,
            isFetchingGlobalPendingChat: value
          }
        }
        case "getGlobalQueuedChats": {
          return {
            ...state,
            isFetchingGlobalQueuedChat: value
          }
        }
        case "getGlobalRejectedBusyChats": {
          return {
            ...state,
            isFetchingGlobalRejectedBusyChat: value
          }
        }
        case "getGlobalRejectedTimeOutChats": {
          return {
            ...state,
            isFetchingGlobalRejectedTimeOutChat: value
          }
        }
        case "getIndividualData": {
          return {
            ...state,
            isFetchingIndividualCount: value
          }
        }
        case "getGlobalAgentStatus": {
          return {
            ...state,
            isFetchingGlobalAgentStatus: value
          }
        }
        case "getGlobalOngoingAvailableChats": {
          return {
            ...state,
            isFetchingGlobalAvailableOngoingChats: value
          }
        }
        case "getAgentTimeLine": {
          return {
            ...state,
            isFetchingAgentTimeline: value
          }
        }
        
        
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;